import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

// import welcome from "../images/welcome.svg"
// import welcomeMobile from "../images/welcome-mobile.svg"
import video from "../images/hompagePic.jpg"
import { Container, Row, Col } from "reactstrap"
import Hero from "../components/hero-adjusted/hero"
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component"

const IndexPage = ({ location }) => (
  <Layout>
    <Seo title="Home" />
    <div id="homeId" className="newPicModal homepage-hero">
      <LazyLoadComponent>
        <Hero
          customPlayButton={true}
          videoImg={video}
          location={location}
          pageTitle="Welcome"
        ></Hero>
      </LazyLoadComponent>
    </div>
    {/* <div className="about-wrapper py-5"> */}
    <Container className="pt-5">
      {/* <div className="covid-note">
        <h2 className="mb-4" style={{ color: "#1588bf" }}>
          COVID-19: Colorado Canine Orthopedics is Gradually Getting Back to
          Normal (or at least our “New Normal”)! 
        </h2>
        <p>
          Colorado Canine Orthopedics is thrilled to welcome clients back into
          our hospital. Of course, if you have any symptoms that might be
          consistent with COVID such as fever, chills, sore throat, cough,
          or shortness of breath we ask you to re-schedule.
        </p>
        <p>
          We will continue to ask everyone within our hospital to wear a mask
          properly positioned over mouth and nose and we request that only one
          owner come into our relatively smallish exam rooms.  
        </p>
        <p>
          Pets have never played a more important role in our lives! 
        </p>
        <p>Thank you for your cooperation.</p>
        <p className="mb-0">
          Sincerely,<br />
          Dr. Michael Bauer, Diplomate American College of Veterinary Surgeons
        </p>
      </div> */}
      <Row className="about-section mt-5">
        <Col md={7} sm={12} className="about-first-wrapper">
          <div className="about-first-section">
            <h3 className="mt-0">ABOUT US</h3>
            <p>
              Colorado Canine Orthopedics (CCO) was established in 1996 by Dr.
              Michael Bauer and has become one of the only, and most well
              established, veterinary orthopedic specialty hospitals in the
              United States with six, experienced board-certified surgeons. We
              are located in Colorado Springs, Colorado but the majority of our
              clients and patients travel from all areas Colorado, New Mexico,
              Kansas and Wyoming for small animal orthopedic surgery. We offer
              same day consultations and surgery.
            </p>
            <Link to="/doctors/bauer-dvm" className="btn mb-4">LEARN MORE</Link>
            <h3>EXPERIENCE MATTERS</h3>
            <p>
              Colorado Canine Orthopedics excels in, and is renowned for canine
              joint disorders, fracture repair and small breed disc herniations.
              As a group we perform more ACL repairs (primarily TPLOs) than any
              other practice in this region of the country. The same is true for
              total hip replacements, arthroscopic procedures and fracture
              repairs. The surgeons at CCO have performed tens of thousands of
              orthopedic surgical procedures. In fact, the surgeons at CCO offer
              more collective expertise and experience than virtually any
              specialty hospital. As pet lovers, we understand that no one wants
              their pet to have surgery. However, if surgery is needed having an
              experienced surgeon becomes critically important. Our technician
              and staff are equally well trained, experienced and compassionate.
            </p>
            <h3>EXCELLENCE IN CUSTOMER SERVICE</h3>
            <p>
              If excellence in surgery is a given, then customer service truly
              sets us apart from other practices. From the moment you call or
              walk into our hospital, it becomes apparent that we care about
              pets and their owners. We understand and share how much pet owners
              love their animals, and we understand what a critically important
              role they play in our lives. Because of this understanding we
              treat you and your pet as though they were our own. As an example,
              we use state of the art pain management including nerve blocks and
              epidurals so most pets can return to the comfort of their own home
              the day of surgery.
            </p>
            <h3>FACILITY</h3>
            <p>
              Colorado Canine Orthopedics has a new, state of the art hospital.
              We designed our hospital around a stress-free patient and client
              concept. Our treatment areas, imaging and operating rooms rival
              human practices. We realize it’s the doctors, staff and customer
              service that makes us special, but if you’re interested please
              take a moment and have a look at our hospital photo gallery.
            </p>
          </div>
        </Col>
        <Col md={5} sm={12} className="d-flex about-second-wrapper">
          <div className="about-second-section d-flex flex-column justify-content-between">
            <div>
              <StaticImage
                src="../images/girl-with-her-dog.png"
                alt="Girl with dog"
                imgClassName="img-fluid AboutImg"
              />
              <StaticImage
                src="../images/man-with-his-dog.jpg"
                alt="Man with dog"
                imgClassName="img-fluid mt-5 man-with-her-dog"
              />
              <Link to="/hospital-gallery">
                <StaticImage
                  src="../images/N11V9A8I.jpg"
                  alt="Dog Video"
                  imgClassName="img-fluid mt-5"
                />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    {/* </div> */}
  </Layout>
)

export default IndexPage
